import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding-top: 165px;

  @media (max-width: 768px) {
    padding-top: 30px;
  }

  .title {
    margin: 32px 0;
  }

  .side-bar {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .featuredImage-wrapper {
    img {
      width: 100%;
    }

    img.icon {
      max-width: 28px;
    }
  }

  .image-container {
    img {
      max-width: 100%;
    }
  }

  .author-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .source-logo {
      width: 40px;
      height: 40px;
    }

    p.author {
      margin-bottom: 0;
      margin-left: 8px;
      margin-right: 8px;
      font-size: 16.2px;
      color: #7b848f;

      span {
        color: #1c3fff;
      }
    }
  }

  .credit-wrapper {
    display: flex;
    align-items: center;
    margin-top: 16px;

    svg {
      margin-right: 8px;
      width: 28px;
      height: 28px;
    }

    p {
      margin-bottom: 0;

      span {
        font-weight: bold;
      }
    }
  }

  .pull-quote {
    @media (min-width: 992px) {
      width: 281px;
      margin-right: 0;
      margin-left: auto;
    }

    .author-name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0;
    }

    .author-role {
      font-size: 14.4px;
      margin-bottom: 0;
    }
  }

  .pull-quote-wrapper {
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 0;
    }

    & p {
      font-size: 22.5px;
      line-height: 140%;
      color: var(--text-color);
    }
  }

  .content {
    margin: 48px 0 100px;

    .pull-quote {
      border-left: 4px solid #01c9a1;
      padding-left: 32px;
      margin: 32px 0;

      @media (min-width: 992px) {
        width: 100% !important;
        margin-right: 0;
        margin-left: auto;
      }

      .author-wrapper {
        display: flex;

        .author-name {
          font-size: 16.2px;
          font-weight: bold;
          margin-bottom: 0;
        }

        .author-role {
          font-size: 16.2px;
          margin-bottom: 0;
        }
      }
    }

    .pull-quote-wrapper {
      margin-top: 16px !important;
      margin-bottom: 16px !important;

      @media (max-width: 768px) {
        margin-top: 0;
      }

      & p {
        font-size: 22.5px;
        line-height: 140%;
        color: var(--text-color);
      }
    }
  }
`;

export const MorePressRelease = styled.div`
  background-color: var(--accent-color);
  margin-top: 150px;
  padding: 96px 0;

  @media (max-width: 768px) {
    margin-top: 72px;
    padding-bottom: 60px;
  }

  .heading {
    margin: 0 auto;
    max-width: 770px;
    text-align: center;
  }

  .sub-title {
    margin-bottom: 48px;
  }
`;
